import React, { useEffect, useState } from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import {Progress, Skeleton, Badge, Button} from "antd";
import HorizontalScroll from "../components/HorizontalScroll";
import VideoPlayer from "../components/VideoPlayer";
import {useDisclosure} from "@nextui-org/react";
import VideoUpload from "../components/VideoUpload";
import { scroller } from 'react-scroll';
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";
import SerieCard from "../components/SerieCard";

const Backdrop = styled.div`
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${props => props.backdrop});
    background-size: cover;
    background-position: center;
`;

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const SerieDetails = ({ backendApiUrl }) => {
    const [serie, setSerie] = useState(null);
    const [similarSeries, setSimilarSeries] = useState(null);
    const [recommendationSeries, setRecommendationSeries] = useState(null);
    const [collection, setCollection] = useState(null);
    const [backdropUrl, setBackdropUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isPrivate, setIsPrivate] = useState(false);
    const query = useQuery();
    const serieId = query.get("id");
    const urlIsPrivate = query.get("private");
    const [videoUrl, setVideoUrl] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const windowDimensions = useWindowDimensions()

    const formatReleaseDate = (dateString, full = true) => {
        const optionFull = { year: "numeric", month: "long", day: "numeric" };
        const optionYear = { year: "numeric" };

        return new Date(dateString).toLocaleDateString(undefined, full ? optionFull : optionYear);
    };

    function convertMinutes(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}h ${remainingMinutes}m`;
    }

    function getTrailerUrl(videos) {
        if (!Array.isArray(videos)) {
            console.error("Invalid videos data");
            return null;
        }

        const trailer = videos.find(video =>
            video.name.toLowerCase().includes("official trailer") && video.site === "YouTube"
        );

        return trailer ? `https://www.youtube.com/embed/${trailer.key}` : null;
    }

    function getOtherUrl(videos) {
        if (!Array.isArray(videos)) {
            console.error("Invalid videos data");
            return [];
        }

        return videos
            .filter(video =>
                !video.name.toLowerCase().includes("official trailer")
            )
            .map(video => `https://www.youtube.com/embed/${video.key}`);
    }

    useEffect(() => {
        setSerie(null)
        setSimilarSeries(null)
        setRecommendationSeries(null)
        setCollection(null)
        setBackdropUrl(null)
        setVideoUrl("")

        if (!serieId) {
            setLoading(false);
            return;
        }

        const getPrivateToggle = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/private/get`);
                if (response.status === 200 && response.data?.value === true) {
                    setIsPrivate(true);
                }
            } catch (err) {
                console.error('Error getting private toggle:', err);
            }
        };

        const getSerie = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/serie/details/${serieId}`);
                setSerie(response.data);
                setBackdropUrl(
                    response.data.backdrop_path
                        ? `https://image.tmdb.org/t/p/w500${response.data.backdrop_path}`
                        : "https://via.placeholder.com/500x281?text=No+Backdrop"
                );
            } catch (err) {
                console.error('Error fetching serie:', err);
            } finally {
                setLoading(false);
            }
        };

        getSerie();
        getPrivateToggle();
        setVideoUrl("")
    }, [serieId]);

    useEffect(() => {
        if (urlIsPrivate === "true") {
            setIsPrivate(true);
        }
    }, [urlIsPrivate]);

    useEffect(() => {
        if (!serie)
            return;

        const getCollection = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/serie/collection/${serie.belongs_to_collection.id}`);
                setCollection(response.data);
            } catch (err) {
                console.error('Error fetching collection:', err);
            }
        };

        const getSimilar = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/serie/similar/${serie.id}`);
                setSimilarSeries(response.data);
            } catch (err) {
                console.error('Error fetching similar:', err);
            }
        };

        const getRecommendation = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/serie/recommendation/${serie.id}`);
                setRecommendationSeries(response.data);
            } catch (err) {
                console.error('Error fetching recommendation:', err);
            }
        };

        if (serie.belongs_to_collection)
            getCollection();

        if (serie.streaming)
            setVideoUrl(`${backendApiUrl}${serie.streaming}`)

        getSimilar()
        getRecommendation()
    }, [serie]);

    if (loading) {
        return (
            <div className={"flex flex-col"}>
                <Skeleton.Node
                    active={true}
                    style={{
                        width: "100%",
                        height: 610,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 1200,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
            </div>
        );
    }

    return (
        serie ? <div>
                <Backdrop backdrop={`https://image.tmdb.org/t/p/w500${serie.backdrop_path}`}>
                    <div className={"py-14 px-10 flex-wrap flex gap-10 justify-center"}>
                        <Badge.Ribbon text={"For Adult"} color="pink" placement={"start"} className={`mt-1 ${serie.adult ? "" : "hidden"}`}>
                            <Badge.Ribbon text="Available" color="green" className={`mt-1 ${serie.streaming || videoUrl ? "" : "hidden"}`}>
                                <img
                                    src={
                                        serie.poster_path
                                            ? `https://image.tmdb.org/t/p/w500${serie.poster_path}`
                                            : "https://via.placeholder.com/130x195?text=No+Image"
                                    }
                                    alt={serie.name}
                                    className={"h-[500px] rounded"}
                                />
                            </Badge.Ribbon>
                        </Badge.Ribbon>
                        <div className={"text-white relative max-w-4xl flex flex-col justify-center"}>
                            <div className={`flex ${!windowDimensions.wWCheck(700) && "flex-col"}`}>
                                <p className={"text-6xl font-bold"}>{serie.name}</p>
                                <p className={`text-3xl text-gray-300 ${windowDimensions.wWCheck(700) && "ml-4"} font-light my-auto pt-2`}>{"(" + formatReleaseDate(serie.first_air_date, false) + " - " + formatReleaseDate(serie.last_air_date, false) + ")"}</p>
                            </div>
                            <div className={"flex gap-6 flex-wrap mt-4"}>
                                <div className={"flex items-center"}>
                                    <Progress percent={serie.vote_average * 10 >= 100 ? 99 : (serie.vote_average * 10).toFixed(2)} strokeColor={{'0%': '#FF4D4F', '40%': '#FFB01B', '60%': '#53C31B'}} showInfo={false} trailColor={"gray"} className={"w-40"}/>
                                    <p className={"text-gray-300 text-lg font-light ml-3 my-auto"}>{(serie.vote_average * 10).toFixed(0)}%</p>
                                </div>
                                <Button className={`${isPrivate ? (!serie.streaming && !videoUrl && urlIsPrivate !== "true") ? "hidden" : "" : "hidden"}`} onClick={() => serie.streaming || videoUrl ? scroller.scrollTo("streaming", {duration: 800, smooth: true, offset: -15,}) : onOpen()}>{serie.streaming || videoUrl ? "WATCH IT NOW" : "UPLOAD THE VIDEO"}</Button>
                            </div>
                            {serie.tagline && <p className={"text-gray-300 text-lg font-light mt-12"}>{serie.tagline}</p>}
                            <p className={"text-4xl font-bold mt-3"}>Synopsis</p>
                            <p className={"mt-2 text-lg"}>{serie.overview}</p>
                            <p className={"text-gray-300 font-light mt-12"}>{serie.number_of_seasons} Seasons and {serie.number_of_episodes} episodes</p>
                            <p className={"text-gray-300 font-light mt-1"}>Genres: {serie.genres.map(genre => genre.name).join(', ')}</p>
                            <p className={"text-gray-300 font-light mt-1"}>{serie.status}</p>
                        </div>
                    </div>
                </Backdrop>
                <div className={`w-full flex ${windowDimensions.wWCheck(900) ? "" : "flex-col"} justify-between my-14 gap-10 app`}>
                    {!(!getTrailerUrl(serie.videos) && getOtherUrl(serie.videos).length === 0) && <div className={`${windowDimensions.wWCheck(900) ? "w-8/12" : ""}`}>
                        {getTrailerUrl(serie.videos) ? <div className={"mx-auto"}>
                                <p className={"w-full font-bold text-4xl"}>Trailer:</p>
                                <iframe
                                    className={"w-full aspect-video rounded-xl mt-5"}
                                    src={getTrailerUrl(serie.videos)}
                                    title="Official Trailer"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </div> :
                            getOtherUrl(serie.videos).length !== 0 ? <div>
                                <p className={"w-full font-bold text-4xl mb-5"}>Other videos:</p>
                                <div className={"grid grid-cols-2 gap-3"}>
                                    {getOtherUrl(serie.videos).slice(0, 4).map(video => (
                                        <iframe
                                            className={"w-full aspect-video rounded-xl"}
                                            src={video}
                                            title="Other video"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen>
                                        </iframe>
                                    ))}
                                </div>
                            </div> : null}
                        {collection && <div className={"mx-auto mt-20"}>
                            <p className={"w-full font-bold text-4xl mb-5"}>{collection.name}:</p>
                            <p className={"w-full text-lg mb-8"}>{collection.overview}</p>
                            <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                                {collection.parts?.length > 0
                                    ? collection.parts.map(serie => (
                                        <SerieCard key={serie.id} serie={serie} center={false}/>
                                    ))
                                    : Array(10).fill(null).map((_, skeletonIndex) => (
                                        <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                            <Skeleton.Node
                                                active={true}
                                                style={{
                                                    width: 250,
                                                    height: 375,
                                                }}
                                                className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                            />
                                            <Skeleton.Node
                                                active={true}
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "22px",
                                                    width: 250,
                                                    height: 90,
                                                }}
                                                className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                            />
                                        </div>
                                    ))}
                            </HorizontalScroll>
                        </div>}
                    </div>}
                    <div className={`${!getTrailerUrl(serie.videos) && getOtherUrl(serie.videos).length === 0 ? "w-full" : `${windowDimensions.wWCheck(900) ? "w-4/12" : ""}`} flex flex-col`}>
                        <div className={`${!getTrailerUrl(serie.videos) && getOtherUrl(serie.videos).length === 0 ? "w-full" : `${windowDimensions.wWCheck(900) ? "w-[550px] ml-auto" : ""}`}`}>
                            <p className={"w-full font-bold text-4xl mb-6"}>Informations:</p>
                            <div className={`${!getTrailerUrl(serie.videos) && getOtherUrl(serie.videos).length === 0 ? "flex w-full gap-10 items-start flex-wrap" : ""}`}>
                                <div>
                                    <p className={`font-bold text-lg ${!getTrailerUrl(serie.videos) && getOtherUrl(serie.videos).length === 0 ? "mt-2" : ""}`}>Original Name:</p>
                                    <p>{serie.original_name}</p>
                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Status:</p>
                                    <p>{serie.status}</p>
                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Original Language:</p>
                                    <p>{serie.original_language}</p>
                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Origin Country:</p>
                                    <p>{serie.origin_country}</p>
                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Adult:</p>
                                    <p>{serie.adult ? 'Yes' : 'No'}</p>
                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Homepage:</p>
                                    <p>{serie.homepage}</p>
                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Spoken Languages:</p>
                                    <p>{serie.spoken_languages.map(language => language.name).join(', ')}</p>

                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Production Countries:</p>
                                    <p>{serie.production_countries.map(country => country.name).join(', ')}</p>
                                </div>
                                <div>
                                    <p className={"font-bold text-lg mt-2"}>Production Companies:</p>
                                    <p>{serie.production_companies.map(company => company.name).join(', ')}</p>
                                </div>
                            </div>
                        </div>
                        {getOtherUrl(serie.videos).length !== 0 && getTrailerUrl(serie.videos) !== null && <div className={`${windowDimensions.wWCheck(900) ? "max-w-[550px] ml-auto" : ""} mt-20  w-full`}>
                            <p className={"w-full font-bold text-4xl mb-5"}>Other videos:</p>
                            <div className={"grid grid-cols-1 gap-5"}>
                                {getOtherUrl(serie.videos).slice(0, 2).map(video => (
                                    <iframe
                                        className={"w-full aspect-video rounded-xl"}
                                        src={video}
                                        title="Other video"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>
                {videoUrl &&
                    <div id={"streaming"} className={`${isPrivate ? "" : "hidden"} mx-auto mt-20 app`}>
                        <p className={"w-full font-bold text-4xl mb-5"}>Streaming:</p>
                        <VideoPlayer videoUrl={videoUrl} />
                    </div>}
                {similarSeries && <div className={"mx-auto mt-20 app"}>
                    <p className={"w-full font-bold text-4xl mb-5"}>Similar Series:</p>
                    <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                        {similarSeries?.length > 0
                            ? similarSeries.map(serie => (
                                <SerieCard key={serie.id} serie={serie}/>
                            ))
                            : Array(10).fill(null).map((_, skeletonIndex) => (
                                <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            width: 250,
                                            height: 375,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                    />
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "22px",
                                            width: 250,
                                            height: 90,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                    />
                                </div>
                            ))}
                    </HorizontalScroll>
                </div>}
                {recommendationSeries && <div className={"mx-auto mt-20 app"}>
                    <p className={"w-full font-bold text-4xl mb-5"}>Recommended Series:</p>
                    <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                        {recommendationSeries?.length > 0
                            ? recommendationSeries.map(serie => (
                                <SerieCard key={serie.id} serie={serie}/>
                            ))
                            : Array(10).fill(null).map((_, skeletonIndex) => (
                                <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            width: 250,
                                            height: 375,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                    />
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "22px",
                                            width: 250,
                                            height: 90,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                    />
                                </div>
                            ))}
                    </HorizontalScroll>
                </div>}
                {isOpen &&<VideoUpload backendApiUrl={backendApiUrl} setVideoUrl={setVideoUrl} onClose={() => onClose()} isOpen={isOpen} uploadElementId={serie.id} type={"serie"}/>}
            </div>
            : <div className={"flex flex-col"}>
                <Skeleton.Node
                    active={true}
                    style={{
                        width: "100%",
                        height: 610,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 1200,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
            </div>
    );
};

export default SerieDetails;
